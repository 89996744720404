.message {
    width: 100vw;
    overflow: auto;
    height: 100vh;
}

.message .top-image{ 
    background-image: url("../image/back-04.png");
}

.message .content {
	width: 3.75rem;
    padding-top: 1rem;
    overflow: auto;
    position: relative;
    height: 100vh;
}

.message .content .messageItem {
    font-size: 0.16rem;
    height: 0.8rem;
    width: 3.375rem;
    margin: 0.1rem auto 0 auto;
}

.message .content .messageItem .itemTitle{
    color: #333;
    font-size: 0.14rem;
    font-family: 微软雅黑, 黑体, 宋体;
    margin-left: 0.06rem;
    margin-bottom: 0.06rem;
}

.message .content .messageItem input {
    height: 0.4rem;
    width: 100%;
    background: #f7f7f7;
    border: 1px solid #eee;
	border-radius: 0.02rem;
	font-size: 0.14rem;
	color: #333;
    padding: 0 .05rem;
    outline: 0;
    -webkit-appearance: none;
	font-family: 微软雅黑, 黑体, 宋体;
}

.message .content .messageItem textarea {
    height: 0.9rem;
    width: 100%;
    background: #f7f7f7;
    border: 1px solid #eee;
	border-radius: 0.02rem;
	font-size: 0.14rem;
	color: #333;
    padding: 0.05rem .05rem 0 .05rem;
    outline: 0;
    -webkit-appearance: none;
	font-family: 微软雅黑, 黑体, 宋体;
}

/* html:not([data-scale]) .am-button-primary::before {
        border: 1PX solid #edb96d;
} */

.message .content .messageItem .submit-btn {
    height: 0.4rem;
    width: 100%;
    background-color: #edb96d;
    text-align: center;
    line-height: 0.4rem;
    margin-top: 0.5rem;
    color: #fff;
    border-radius: 0.02rem;
    cursor: pointer;
}

.message .content .messageItem .submit-btn:disabled {
    opacity: 0.5;
}