.page-com {
    width: 3.375rem;
    margin: 0 auto;
    height: 0.5rem;
    font-size: 0.12rem;
    text-align: center;
    line-height: 0.3rem;
    padding-top: 0.1rem;
    color: #333;
    display: flex;
    justify-content: flex-end;
    margin-top: 0.1rem;
}

.page-com .previous {
    width: 0.6rem;
    height: 0.3rem;
    background-size: 90% auto;
    border-radius: 0.04rem 0 0 0.04rem;
    border: 1px #ccc solid;
    background-color: #fff;
    vertical-align: middle;
}

.disenable {
    color: #d2d2d2!important;
    cursor: not-allowed!important;
}

.page-com .current {
    width: 0.4rem;
    height: 0.3rem;
    background-color: #fff;
    border-top: 1px #ccc solid;
    border-bottom: 1px #ccc solid;
    vertical-align: middle;
}

.page-com .next {
    width: 0.6rem;
    height: 0.3rem;
    background-size: 90% auto;
    border-radius: 0 0.04rem 0.04rem 0;
    border: 1px #ccc solid;
    background-color: #fff;
    vertical-align: middle;
}
