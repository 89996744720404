.bottom {
    width: 3.75rem;
    height: 0.5rem;
    background-color: #fff;
    position: absolute;
    box-shadow: 0 -0.025rem 0.25rem 0 rgb(189 199 217 / 60%);
    z-index: 100;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
}

.bottom .item {
    font-size: 0.13rem;
    text-align: center;
    height: 0.5rem;
    width: 0.7rem;
    font-family: "微软雅黑","黑体","宋体";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.bottom .item .icon {
    height: 0.25rem;
    width: 0.25rem;
}

.bottom .item .txt {
    height: 0.2rem;
    width: 0.7rem;
    line-height: 0.2rem;
}

.bottom a {
    text-decoration:none;
    color:#333;
}