.details {
    width: 3.75rem;
    height: 100vh;
    display: flex;
}

.details .top {
    height: 0.4rem;
    width: 3.75rem;
    display: flex;
    border-bottom: 1px solid #CCC;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FFF;
}

.details .top .return-icon {
    height: 0.4rem;
    width: 0.3rem;
    background-image: url("../image/goback.png");
    background-repeat: no-repeat;
    background-size: auto 50%;
    background-position: center;
}

.details .top .details-name {
    height: 0.4rem;
    flex: 1 1;
    font-size: 0.18rem;
    line-height: 0.41rem;
    font-weight: 500;
    font-family: "微软雅黑","黑体","宋体";
}

.details .article {
    flex: 1;
    width: 3.75rem;
    font-size: .16rem;
    font-family: "微软雅黑","黑体","宋体";
    padding-top: 0.4rem;
    overflow: auto;
}

.details .article .article-title {
    width: 3.5rem;
    margin: 0.1rem auto 0 auto;
    font-size: .2rem;
    font-weight: 700;
}

.details .article .other {
    width: 3.5rem;
    margin: 0.1rem auto 0 auto;
    display: flex;
    font-size: 0.16rem;
    color: #CCC;
}

.details .article .other .author {
    width: 50%;
}

.details .article .other .time {
    width: 50%;
}

.details .article .article-content {
    width: 3.5rem;
    margin: 0.1rem auto 0 auto;
    line-height: 0.25rem;
    white-space: pre-wrap;
    /* white-space: pre-line; */
    font-size: 0.18rem;
    color: #333;
}