.self-top {
    display: flex;
}

.self-top .head {
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    background-image: url("../image/yu.jpg");
    background-repeat: no-repeat;
    box-shadow: 0 0.075rem 0.5rem 0 rgb(162 176 203 / 40%);
    background-size: 100% auto;
}

.self-top .right {
    width: 1.6rem;
    height: 1rem;
    margin-left: 0.05rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    font-size: 0.15rem;
}

.self-top .right .name {
    width: 1.6rem;
    height: 0.3rem;
    font-size: 0.23rem;
    color: #333;
    font-weight: 700;
    line-height: 0.3rem;
}

.self-top .right .post {
    width: 1.2rem;
    height: 0.3rem;
    border: 1px solid #f8c272;
    line-height: 0.3rem;
    border-radius: 0.2rem;
    font-size: 0.14rem;
    text-align: center;
    font-family: "微软雅黑","黑体","宋体";
    color: #DCD;
}

.self-top .right .org {
    width: 1.6rem;
    height: 0.3rem;
}