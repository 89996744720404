.page-three {
    width: 100vw;
    overflow: auto;
    height: 100vh;
}

.page-three .top-image {
    background-image: url("../image/back-03.png");
}

.page-three .content {
    position: relative;
    z-index: 100;
    width: 3.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-top: 0.4rem;
    margin: 0 auto;
}

.page-three .content .content-item {
    width: 3.375rem;
    /* height: 1.5rem; */
    background-color: #FFFFFF;
    border-radius: 0.1rem;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0 0 0.1rem 0 rgba(159, 185, 165, .35);
    border: 0.01rem solid #fff;
    padding: 0.11rem 0.2rem;
    margin-top: .1rem;
    position: relative;
}

.page-three .content .content-item .article-number {
    font-weight: 400;
    width: .2rem;
    height: .2rem;
    line-height: .2rem;
    text-align: center;
    background: #f8c272;
    color: #fff;
    font-size: .16rem;
    border-radius: .035rem;
    box-shadow: 0.01rem 0.01rem rgb(237 185 109 / 40%);
    display: inline-block;
    margin-right: .06rem;
    vertical-align: top;
    margin-top: .02rem;
}

.page-three .content .content-item .article-title {
    font-size: 0.18rem;
    color: #333;
    font-weight: 600;
}

.page-three .content .content-item .describe {
    font-size: 0.14rem;
    color: #666;
    padding-top: 0.05rem;
    line-height: 0.25rem;
    height: 0.8rem;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    /** 将对象作为伸缩盒子模型显示 **/
    -webkit-box-orient: vertical;
    /** 设置或检索伸缩盒对象的子元素的排列方式 **/
    -webkit-line-clamp: 3;
    /** 显示的行数 **/
    overflow: hidden;
    /** 隐藏超出的内容 **/

}

.page-three .content .content-item .author {
    font-size: 0.12rem;
    color: #999;
    display: flex;
    margin-top: 0.05rem;
    align-items: center;
}

.page-three .content .content-item .author .article-time {
    margin-left: 0.3rem;
}