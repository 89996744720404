.page-four {
    width: 100vw;
    overflow: auto;
    height: 100vh;
    padding-top: 0.4rem;
    overflow: auto;
    position: relative;
}

.page-four .top-image {
    background-image: url("../image/back-04.png");
}

.page-four .content::before {
    content: "给我留言";
    color: #FFF;
    background-color: #edb96d;
    right: 0;
    top: 0.74rem;
    width: 0.65rem;
    height: 0.25rem;
    text-align: center;
    line-height: 0.25rem;
    font-size: 0.14rem;
    position: absolute;
    border-radius: 0.05rem 0 0 0.05rem;
    pointer-events: auto;    /* 关键点在这里，伪元素覆盖父元素的 pointer-events: none ，响应鼠标事件 */
}

.page-four .content {
    width: 3.375rem;
    margin: 0 auto;
    background-color: #FFF;
    border-radius: 0.15rem;
    box-shadow: 0 0.075rem 0.5rem 0 rgb(162 176 203 / 40%);
    padding: 0.1rem;
    position: relative;
    pointer-events: none;    /* 关键点在这里，元素禁止响应鼠标事件 */
}

.page-four .content .space {
    margin: 0.2rem auto 0 auto;
    height: 1px;
    background-color: #CCC;
}

.page-four .content .self-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: spase-evenly;
}

.page-four .content .self-info .self-item {
    width: 100%;
    display: flex;
    padding: 0.1rem;
}

.page-four .content .self-info .self-item .self-icon {
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 0.08rem;
    background-repeat: no-repeat;
    background-size: 80% auto;
    background-position: center;
}

.page-four .content .self-info .self-item .phone {
    background-color: #ffeee1;
    background-image: url('../image/self/phone.png');
}

.page-four .content .self-info .self-item .mobile {
    background-color: #e3faf5;
    background-image: url('../image/self/mobile.png');
}

.page-four .content .self-info .self-item .email {
    background-color: #ffe8eb;
    background-image: url('../image/self/email.png');
}

.page-four .content .self-info .self-item .language {
    background-color: #f1e5ff;
    background-image: url('../image/self/language.png');
}

.page-four .content .self-info .self-item .address {
    background-color: #d5f1d4;
    background-image: url('../image/self/address.png');
}

.page-four .content .self-info .self-item .post {
    background-color: #e3ecfc;
    background-image: url('../image/self/post.png');
}

.page-four .content .self-info .self-item .self-txt {
    margin-left: 0.1rem;
    line-height: 0.25rem;
    flex: 1;
    font-size: 0.16rem;
    color: #444;
    display: -webkit-box;
}

.submit-btn {
    height: 0.4rem;
    width: 100%;
    background-color: #edb96d;
    text-align: center;
    line-height: 0.4rem;
    margin-top: 0.5rem;
    color: #fff;
    border-radius: 0.02rem;
    cursor: pointer;
}