.page-one {
    width: 100vw;
    overflow: auto;
    height: 100vh;
}

.page-one .top-image{ 
    background-image: url("../image/back-02.png");
}

.page-one .main {
    width: 3.75rem;
    padding-top: 0.4rem;
    overflow: auto;
    position: relative;
    height: 100vh;
}

.page-one .content {
    width: 3.375rem;
    margin: 0 auto;
    height: 1.2rem;
    background-color: #FFF;
    border-radius: 0.15rem;
    box-shadow: 0 0.075rem 0.5rem 0 rgb(162 176 203 / 40%);
    /* display: flex; */
    padding: 0.1rem;
    position: relative;
}

.page-one .synopsis{
    width: 3.375rem;
    margin: 0.1rem auto 0 auto;
    height: 2rem;
    background-color: #FFF;
    border-radius: 0.1rem;
    box-shadow: 0 0.075rem 0.5rem 0 rgb(162 176 203 / 40%);
    display: flex;
    padding: 0.1rem;
    flex-direction: column;
}

.page-one .synopsis .info {
    width: 100%;
    font-size: 0.15rem;
    padding: 0.06rem;
    overflow: hidden;
    font-family: "微软雅黑","黑体","宋体";
}

.page-one .domain {
	width: 3.375rem;
	height: 1.5rem;
    margin: 0.1rem auto 0 auto;
	display: flex;
	align-items: center;
	flex-direction: column;
    background-color: #FFF;
    border-radius: 0.15rem;
    box-shadow: 0 0.075rem 0.5rem 0 rgb(162 176 203 / 40%);
    padding: 0.1rem;
}

.page-one .domain .title {
	height: 0.5rem;
}

.page-one .domain .business {
	width: 3.375rem;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-wrap: wrap;
	height: 1rem;
}

.page-one .domain .business .businessItem {
	width: 0.85rem;
	height: 0.3rem;
	line-height: 0.3rem;
	border-radius: 0.06rem;
    background: rgba(159,165,185,.2);
    font-family: "微软雅黑","黑体","宋体";
	text-align: center;
	font-size: 0.16rem;
	color:  #9fa5b9;
}