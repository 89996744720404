* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.top-image {
    height: 1rem;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: 100% auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.perch {
    width: 3.75rem;
    height: 0.6rem;
}

.title {
    width: 100%;
    height: 0.25rem;
    display: flex;
    align-items: center;
    line-height: 0.3rem;
}

.title-line {
    width: 0.03rem;
    height: 0.14rem;
    border-radius: 0.01rem;
    background: #f8c272;
    display: block;
    margin-left: 0.1rem;
}

.title-txt {
    word-break: break-all;
    font-size: 0.16rem;
    color: #333;
    padding-left: 0.08rem;
    font-weight: 700;
    flex: 1;
}

.broadside {
    height: 0.7rem;
    width: 0.3rem;
    position: absolute;
    right: 0.1rem;
    bottom: 2.5rem;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.tell-me {
    display: block;
    height: 0.3rem;
    width: 0.3rem;
    background-color: #f8c272;
    opacity: 0.8;
    border-radius: 50%;
    box-shadow: 0.05rem 0.125rem 0.625rem 0 rgb(236 184 108 / 55%);
    background-image: url("../image/tell-me.png");
    background-repeat: no-repeat;
    background-size: 80% auto;
    background-position: center;
}

.message-me {
    display: block;
    height: 0.3rem;
    width: 0.3rem;
    background-color: #f8c272;
    opacity: 0.8;
    border-radius: 50%;
    box-shadow: 0.05rem 0.125rem 0.625rem 0 rgb(236 184 108 / 55%);
    background-image: url("../image/message.png");
    background-repeat: no-repeat;
    background-size: 70% auto;
    background-position: center;
}

.beian {
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
