.loadding {
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.6); */
    z-index: 200;
}

.loadding .icon{
    height: 2rem;
    width: 2rem;
    margin: 50% auto 0 auto;
    background-image: url("../image/loadding.jpg");
    background-repeat: no-repeat;
    background-size: 100% auto;
}